import React from "react";
import { Provider } from "react-redux";
import AppRoute from "./routes/index";
import configureStore from "./redux/configureStore";
import AuthProvider from "./providers/AuthProvider";
// import NotificationsProvider from "./providers/NotificationsProvider";

const store = configureStore();

const App = () => (
	<Provider store={store}>
		<AuthProvider>
			{/* <NotificationsProvider> */}
			<AppRoute />
			{/* </NotificationsProvider> */}
		</AuthProvider>
	</Provider>
);

export default App;
