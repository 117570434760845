import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// @material-ui/icons
import Person from '@material-ui/icons/AccountCircle';
import Notifications from '@material-ui/icons/Notifications';

// core components
import CustomInput from '../CustomInput/CustomInput';
import Button from '../Buttons/CustomButton';

import adminNavbarLinksStyle from '../../assets/styles/components/adminNavbarLinksStyle';
import { Typography } from '@material-ui/core';

const accountItems = [
  {
    id: 1,
    text: 'Profile',
  },
  {
    id: 2,
    text: 'Settings',
  },
  {
    id: 3,
    text: 'Log out',
  },
];

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openProfile: false,
  };

  handleClick = () => {
    this.setState({ open: !this.state.open, openProfile: false });
  };
  handleProfileClick = () => {
    this.setState({ open: false, openProfile: !this.state.openProfile });
  };
  handleClose = () => {
    this.setState({ open: false, openProfile: false });
  };
  onNotificationClick = (notification) => {
    this.setState({ open: false, openProfile: false });
    this.props.onNotificationClick(notification);
  };

  render() {
    const { classes, rtlActive, notifications } = this.props;
    const { open, openProfile } = this.state;
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapper]: true,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    return (
      <div className={wrapper} style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <div className={managerClasses}>
          <Button
            color='transparent'
            justIcon
            aria-label='Notifications'
            aria-owns={open ? 'menu-list' : null}
            aria-haspopup='true'
            onClick={this.handleClick}
            className={classes.buttonLink}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
          >
            <Notifications
              className={classes.headerLinksSvg + ' ' + classes.links}
            />
            {notifications.length > 0 ? (
              <span className={classes.notifications}>
                {notifications.length}
              </span>
            ) : null}
          </Button>

          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement='bottom'
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true,
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id='menu-list'
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <List>
                      {notifications && notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                          <ListItem
                            className={classes.notificItem}
                            key={index}
                            onClick={() =>
                              this.onNotificationClick(notification)
                            }
                          >
                            <ListItemText
                              primary={notification.title}
                              secondary={notification.body}
                            />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem
                          onClick={this.onNotificationClick}
                          className={classes.notificItem}
                        >
                          No Notification
                        </ListItem>
                      )}
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <Button
          color='transparent'
          aria-label='Person'
          justIcon
          onClick={this.handleProfileClick}
          className={classes.buttonLink}
          buttonRef={(node) => {
            this.anchorEl2 = node;
          }}
        >
          {this.props.userProfile && this.props.userProfile.image ? (
            <img
              src={this.props.userProfile.image}
              className={classes.headerLinksSvg + ' ' + classes.links}
            />
          ) : (
            <Person className={classes.headerLinksSvg + ' ' + classes.links} />
          )}
        </Button>
        <Popper
          open={openProfile}
          anchorEl={this.anchorEl2}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true,
          })}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='menu-list'
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role='menu'>
                    <MenuItem
                      onClick={() => this.props.history.push('/profile')}
                      className={dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={this.handleClose}
                      className={dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <MenuItem
                      onClick={this.props.logout}
                      className={dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

export default withStyles(adminNavbarLinksStyle)(withRouter(HeaderLinks));
