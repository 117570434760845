import React from 'react';
import reactDOM from 'react-dom';
import 'react-dates/lib/css/_datepicker.css';
import 'normalize.css/normalize.css';
import App from './App';

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./service-worker.js');
  }
});

reactDOM.render(<App />, window.document.getElementById('root'));
