export const FETCH_LEAGUES = "FETCH_LEAGUES";
export const FETCH_LEAGUE_SEASONS = "FETCH_LEAGUE_SEASONS";
export const FETCH_SEASON_DETAILS = "FETCH_SEASON_DETAILS";
export const FETCH_TEAMS = "FETCH_TEAMS";
export const UPDATE_LEAGUE = "UPDATE_LEAGUE";
export const CREATE_LEAGUE = "CREATE_LEAGUE";
export const FETCH_LEAGUE = "FETCH_LEAGUE";
export const UPDATE_SEASON = "UPDATE_SEASON";
export const CREATE_SEASON = "CREATE_SEASON";
export const CREATE_TEAM = "CREATE_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const CREATE_MATCH = "CREATE_MATCH";
export const UPDATE_MATCH = "UPDATE_MATCH";