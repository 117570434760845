import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, Link } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { Typography } from "@material-ui/core";
import sidebarStyle from "../../assets/styles/components/sidebarStyle";
import logoImg from "../../assets/images/logo.jpg";
import avatar from "../../assets/images/defaultImages/user.png";
import componentAllowed from "../../helpers/authorization/componentAllowed";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openAvatar: false,
			miniActive: true,
			...this.getCollapseStates(props.routes),
		};
	}

	componentDidMount() {
		const activeRoute = this.props.routes.filter(
			(el) => window.location.pathname.indexOf(el.page) > -1
		)[0];
		if (activeRoute && activeRoute.collapse) {
			const sideElementInview = document.getElementById(
				`menu${activeRoute.page}`
			);
			if (sideElementInview) sideElementInview.scrollIntoView();
		}
	}
	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = (routes) => {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}
	activeRoute = (routeName) => {
		return window.location.pathname === routeName ? "active" : "";
	};
	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = (routes) => {
		const { classes, color = "blue", rtlActive, userProfile } = this.props;
		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.collapse) {
				var st = {};
				st[prop["state"]] = !this.state[prop.state];
				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes.collapseActive]: this.getCollapseInitialState(
							prop.views
						),
					});
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.itemTextRTL]: rtlActive,
					});
				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive,
					});
				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive,
					});
				const caret =
					classes.caret +
					" " +
					cx({
						[classes.caretRTL]: rtlActive,
					});
				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive,
					});
				return (
					componentAllowed(prop.roles, "org") && (
						<ListItem
							onClick={() => this.props.handleDrawerToggle(true)}
							key={key}
							id={`menu${prop.page}`}
							className={cx(
								{ [classes.item]: prop.icon !== undefined },
								{ [classes.collapseItem]: prop.icon === undefined }
							)}
						>
							<NavLink
								to={"#"}
								className={navLinkClasses}
								onClick={(e) => {
									e.preventDefault();
									this.setState(st);
								}}
							>
								{prop.icon !== undefined ? (
									typeof prop.icon === "string" ? (
										<Icon className={itemIcon}>{prop.icon}</Icon>
									) : (
										<prop.icon className={itemIcon} />
									)
								) : (
									<span className={collapseItemMini}>
										{rtlActive ? prop.rtlMini : prop.mini}
									</span>
								)}
								<ListItemText
									primary={
										rtlActive
											? prop.rtlName
											: userProfile.claims.artistId ||
											    userProfile.claims.producerId
											  ? prop.customName
											  : prop.name
									}
									secondary={
										<b
											className={
												caret +
												" " +
												(this.state[prop.state] ? classes.caretActive : "")
											}
										/>
									}
									disableTypography={true}
									className={cx(
										{ [itemText]: prop.icon !== undefined },
										{ [collapseItemText]: prop.icon === undefined }
									)}
								/>
							</NavLink>
							<Collapse in={this.state[prop.state]} unmountOnExit>
								<List className={classes.list + " " + classes.collapseList}>
									{this.createLinks(prop.views)}
								</List>
							</Collapse>
						</ListItem>
					)
				);
			} else {
				const innerNavLinkClasses =
					classes.collapseItemLink +
					" " +
					cx({
						[" " + classes[color]]: this.activeRoute(prop.path),
					});
				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive,
					});
				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes[color]]: this.activeRoute(prop.path),
					});
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.itemTextRTL]: rtlActive,
					});
				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive,
					});
				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive,
					});

				return (
					componentAllowed(prop.roles, "org") && (
						<ListItem
							onClick={() => this.props.handleDrawerToggle(true)}
							key={key}
							className={cx(
								{ [classes.item]: prop.icon !== undefined },
								{ [classes.collapseItem]: prop.icon === undefined }
							)}
						>
							<NavLink
								to={prop.path}
								className={cx(
									{ [navLinkClasses]: prop.icon !== undefined },
									{ [innerNavLinkClasses]: prop.icon === undefined }
								)}
							>
								{prop.icon !== undefined ? (
									typeof prop.icon === "string" ? (
										<Icon className={itemIcon}>{prop.icon}</Icon>
									) : (
										<prop.icon className={itemIcon} />
									)
								) : (
									<span className={collapseItemMini}>
										{rtlActive ? prop.rtlMini : prop.mini}
									</span>
								)}
								<ListItemText
									primary={rtlActive ? prop.rtlName : prop.name}
									disableTypography={true}
									className={cx(
										{ [itemText]: prop.icon !== undefined },
										{ [collapseItemText]: prop.icon === undefined }
									)}
								/>
							</NavLink>
						</ListItem>
					)
				);
			}
		});
	};

	render() {
		const { classes, logo, image, logoText, routes, bgColor, rtlActive } =
			this.props;
		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive,
			});
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive,
			});
		const userWrapperClass =
			classes.user +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white",
			});
		const caret =
			classes.caret +
			" " +
			cx({
				[classes.caretRTL]: rtlActive,
			});
		const collapseItemMini =
			classes.collapseItemMini +
			" " +
			cx({
				[classes.collapseItemMiniRTL]: rtlActive,
			});
		const photo =
			classes.photo +
			" " +
			cx({
				[classes.photoRTL]: rtlActive,
			});
		var user = (
			<div className={userWrapperClass}>
				<div className={photo}>
					<img
						src={
							this.props.userProfile && this.props.userProfile.image
								? this.props.userProfile.image
								: avatar
						}
						className={classes.avatarImg}
						alt="..."
					/>
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						<NavLink
							to={"/profile"}
							className={classes.itemLink + " " + classes.userCollapseButton}
							onClick={() => this.openCollapse("openAvatar")}
						>
							<ListItemText
								primary={
									this.props.userProfile && this.props.userProfile.name
										? this.props.userProfile.name
										: "Music Admin"
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/>
						</NavLink>
					</ListItem>
				</List>
			</div>
		);
		var links = (
			<List className={classes.list}>{this.createLinks(routes)}</List>
		);

		const logoNormal =
			classes.logoNormal +
			" " +
			cx({
				[classes.logoNormalSidebarMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.logoNormalSidebarMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.logoNormalRTL]: rtlActive,
			});
		const logoMini =
			classes.logoMini +
			" " +
			cx({
				[classes.logoMiniRTL]: rtlActive,
			});
		const logoClasses =
			classes.logo +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white",
			});
		var brand = (
			<div className={logoClasses}>
				<Link to="/" className={logoMini}>
					<img
						src={logoImg}
						alt="logo"
						className={classes.img}
						style={{ borderRadius: "100%" }}
					/>
				</Link>
				<Link to="/" className={logoNormal}>
					<Typography variant="subtitle1">&nbsp;InyaRwanda Portal</Typography>
				</Link>
			</div>
		);
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive,
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1,
			});
		return (
			<div ref="mainPanel">
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={"left"}
						open={this.props.open}
						classes={{
							paper: drawerPaper + " " + classes.blackBackground,
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						onMouseOver={() => this.setState({ miniActive: false })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor={"left"}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + " " + classes.blackBackground,
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: "blue",
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"white",
		"red",
		"orange",
		"green",
		"blue",
		"purple",
		"rose",
	]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(sidebarStyle)(Sidebar);
