import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { primaryColor } from "../assets/styles/base";

const useStyles = makeStyles({
	root: {
		padding: "10vh 0",
		textAlign: "center",
		width: "100%",
	},
	loader: {
		display: "inline-block",
		color: primaryColor[0],
	},
});

export default (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress className={classes.loader} />
		</div>
	);
};
