// @material-ui/icons
import AuthorsIcon from "@material-ui/icons/SupervisorAccount";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountsIcon from "@material-ui/icons/AccountCircle";
import Folder from "@material-ui/icons/Folder";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DescriptionIcon from "@material-ui/icons/Description";
import PostIcon from "@material-ui/icons/PostAdd";
import LiveIcon from "@material-ui/icons/LiveTv";
import BannerIcon from "@material-ui/icons/BrandingWatermark";
import LeaguesIcon from "@material-ui/icons/EmojiEvents";

const { email, uid, claims } = JSON.parse(
	localStorage.getItem("au") || '{"claims":{}}'
);
const userOrg = claims.userOrg || {};

const dashRoutes = [
	{
		path: "/",
		name: "Dashboard",
		icon: DashboardIcon,
		page: "dashboard",
		roles: ["admin", "editor", "chiefEditor", "owner"],
		accessLevel: "org",
	},
	{
		collapse: true,
		name: "Articles",
		customName: "Articles",
		icon: DescriptionIcon,
		state: "ArticlesCollapse",
		page: "articles",
		roles: ["admin", "chiefEditor", "owner", "editor"],
		accessLevel: "org",
		views: [
			{
				path: "/articles",
				name: "Publsihed Articles",
				mini: "PA",
				page: "publsihedArticles",
				roles: ["admin", "chiefEditor", "owner", "editor"],
				accessLevel: "org",
			},
			{
				path: "/articles/pending",
				name: "Pending Articles",
				mini: "PA",
				page: "pendingArticles",
				roles: ["admin", "chiefEditor", "owner", "editor"],
				accessLevel: "org",
			},
			{
				path: "/articles/new",
				name: "Create Article",
				mini: "CA",
				page: "createArticles",
				roles: ["admin", "chiefEditor", "owner", "editor"],
				accessLevel: "org",
			},
		],
	},
	{
		path: "/live",
		name: "Live Posts",
		icon: LiveIcon,
		page: "live",
		roles: ["admin", "editor", "chiefEditor", "owner"],
		accessLevel: "org",
	},
	{
		path: "/sports/leagues",
		name: "Leagues",
		icon: LeaguesIcon,
		page: "leagues",
		roles: ["admin", "chiefEditor", "owner", "editor"],
		accessLevel: "org",
	},
	// {
	// 	path: "/posts",
	// 	name: "Social media",
	// 	icon: PostIcon,
	// 	page: "posts",
	// 	roles: ["admin", "chiefEditor", "owner"],
	// },
	{
		path: "/banners",
		name: "Banners",
		icon: BannerIcon,
		page: "banners",
		roles: ["admin", "chiefEditor", "owner"],
		accessLevel: "org",
	},

	// {
	// 	path: "/authors",
	// 	name: "Authors",
	// 	page: "authors",
	// 	icon: AuthorsIcon,
	// 	roles: ["admin", "chiefEditor", "owner"],
	// },
	{
		path: `/orgs/${userOrg.orgId}`,
		name: "Account",
		page: "account",
		icon: AuthorsIcon,
		roles: ["admin", "chiefEditor", "owner"],
		accessLevel: "org",
	},
	{
		path: `/authors/${uid}`,
		name: "My Stats",
		page: "myrecords",
		icon: Folder,
		roles: ["admin", "chiefEditor", "owner", "editor"],
		accessLevel: "org",
	},
	{
		path: `/report`,
		name: "Report",
		page: "report",
		icon: AssignmentIcon,
		roles: ["admin", "chiefEditor", "owner"],
		accessLevel: "org",
	},
	{
		path: "/orgs",
		name: "Organizations",
		page: "orgs",
		mini: "ORG",
		permission: "read",
		icon: AccountsIcon,
		roles: ["admin", "owner"],
		accessLevel: "org",
	},
];

export default dashRoutes;
