import {
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  FETCH_ARTICLES,
  FETCH_ARTICLE_CATEGORIES,
  FETCH_ARTICLE,
  FETCH_ARTICLES_ADS,
  FETCH_ARTICLE_AD,
} from '../types';

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export const createArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_ARTICLE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${CREATE_ARTICLE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.error ? action.payload.message : null,
      };

    case `${CREATE_ARTICLE}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const updateArticleReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_ARTICLE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${UPDATE_ARTICLE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${UPDATE_ARTICLE}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ARTICLES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_ARTICLES}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${FETCH_ARTICLES}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const articleCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ARTICLE_CATEGORIES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_ARTICLE_CATEGORIES}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${FETCH_ARTICLE_CATEGORIES}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ARTICLE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_ARTICLE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${FETCH_ARTICLE}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const articlesAdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ARTICLES_ADS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_ARTICLES_ADS}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${FETCH_ARTICLES_ADS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const articleAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ARTICLE_AD}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_ARTICLE_AD}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${FETCH_ARTICLE_AD}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
