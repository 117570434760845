// @collapse
import {
  FETCH_LEAGUES,
  FETCH_LEAGUE_SEASONS,
  FETCH_SEASON_DETAILS,
  FETCH_TEAMS,
  CREATE_LEAGUE,
  UPDATE_LEAGUE,
  CREATE_SEASON,
  UPDATE_SEASON,
  CREATE_TEAM,
  UPDATE_TEAM
} from "../types";

const initialState = {
  loading: false,
  error: null,
  data: {},
  message: null
};

export const leaguesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_LEAGUES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_LEAGUES}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_LEAGUES}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
export const createLeagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_LEAGUE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${CREATE_LEAGUE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        message: action.payload.message,
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${CREATE_LEAGUE}_REJECTED`:
      return {
        ...state,
        loading: false,
        message: `${action.payload.message}`,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
export const updateLeagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_LEAGUE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${UPDATE_LEAGUE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        message: action.payload.message,
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${UPDATE_LEAGUE}_REJECTED`:
      return {
        ...state,
        loading: false,
        message: `${action.payload.message}`,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const seasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_LEAGUE_SEASONS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_LEAGUE_SEASONS}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_LEAGUE_SEASONS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
export const createseasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_SEASON}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${CREATE_SEASON}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${CREATE_SEASON}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
export const updateSeasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_SEASON}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${UPDATE_SEASON}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${UPDATE_SEASON}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};


export const seasonDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_SEASON_DETAILS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_SEASON_DETAILS}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_SEASON_DETAILS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};


export const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_TEAMS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${FETCH_TEAMS}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${FETCH_TEAMS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
export const createTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_TEAM}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${CREATE_TEAM}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${CREATE_TEAM}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};
export const updateTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_TEAM}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case `${UPDATE_TEAM}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload || {},
        error: action.payload.message
          ? action.payload.message.includes("Network Error") && "Network Error"
          : null,
      };

    case `${UPDATE_TEAM}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};