import { GET_ORGANIZATIONS, GET_ORGANIZATION } from "../types";

const initialState = {
  loading: false,
  data: {},
};

export default class Organizations {
  static organizationsReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_ORGANIZATIONS}_PENDING`:
        return { ...state, loading: true };
      case `${GET_ORGANIZATIONS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_ORGANIZATIONS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static organizationReducer(state = initialState, action) {
    switch (action.type) {
      case `${GET_ORGANIZATION}_PENDING`:
        return { ...state, loading: true };
      case `${GET_ORGANIZATION}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_ORGANIZATION}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
