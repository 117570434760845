import { GET_PENDING_POSTS } from './types';

const initialState = {
  loading: false,
  data: {},
};

export default class PostsReducer {
  static getPendingPosts(state = initialState, action) {
    switch (action.type) {
      case `${GET_PENDING_POSTS}_PENDING`:
        return { ...state, loading: true };
      case `${GET_PENDING_POSTS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${GET_PENDING_POSTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
