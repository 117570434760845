import {
	CREATE_BANNER,
	FETCH_BANNER,
	FETCH_BANNERS,
	FETCH_BANNERS_SECTIONS,
	UPDATE_BANNER,
} from "../types";

const initialState = {
	loading: false,
	error: null,
	data: {},
};

export const createBannerReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${CREATE_BANNER}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${CREATE_BANNER}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.error ? action.payload.message : null,
			};

		case `${CREATE_BANNER}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const updateBannerReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${UPDATE_BANNER}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${UPDATE_BANNER}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${UPDATE_BANNER}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const bannersReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_BANNERS}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${FETCH_BANNERS}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${FETCH_BANNERS}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const bannerSectionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_BANNERS_SECTIONS}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${FETCH_BANNERS_SECTIONS}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${FETCH_BANNERS_SECTIONS}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const bannerReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_BANNER}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${FETCH_BANNER}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${FETCH_BANNER}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};
