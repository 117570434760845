import {
	bannerReducer,
	bannerSectionsReducer,
	bannersReducer,
	createBannerReducer,
	updateBannerReducer,
} from "./reducers/reducers";

const bannersReducers = {
	createBanner: createBannerReducer,
	updateBanner: updateBannerReducer,
	banners: bannersReducer,
	bannerSections: bannerSectionsReducer,
	banner: bannerReducer,
};

export { bannersReducers };
