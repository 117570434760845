import { compact, concat, uniqBy } from "lodash";
import sortSearchResults from "../../../helpers/sortSearchResults";
import { ARTIST_SEARCH, CLEAR_SEARCH } from "../types";

const initialState = {
  searching: false,
  data: { results: [] },
  error: null,
};

export const artistSearchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${ARTIST_SEARCH}_PENDING`:
      return { ...state, error: null, searching: true };

    case `${ARTIST_SEARCH}_FULFILLED`:
      const dataResult = action.payload.data
        ? action.payload.data.data.results
        : [];

      const data = sortSearchResults(
        uniqBy(compact(concat(state.data.results, dataResult)), "id"),
        action.payload.term
      );

      return {
        ...state,
        searching: false,
        data: { results: data },
        error:
          action.payload && action.payload.message
            ? action.payload.message.includes("Network Error") &&
              "Network Error"
            : null,
      };
    case `${ARTIST_SEARCH}_REJECTED`:
      return {
        ...state,
        searching: false,
        data: null,
        error: `${action.payload}`,
      };
    case CLEAR_SEARCH:
      return initialState;

    default:
      return state;
  }
};
