import {
  leaguesReducer,
  seasonsReducer,
  seasonDetailsReducer,
  teamsReducer,
  createLeagueReducer,
  updateLeagueReducer,
  createseasonReducer,
  updateSeasonReducer,
  createTeamReducer,
  updateTeamReducer
} from "./reducres/reducers";

const leagueReducers = {
  leagues: leaguesReducer,
  createleague: createLeagueReducer,
  updateleague: updateLeagueReducer,
  seasons: seasonsReducer,
  createSeason: createseasonReducer,
  updateSeason: updateSeasonReducer,
  seasonDetails: seasonDetailsReducer,
  teams: teamsReducer,
  createTeam: createTeamReducer,
  updateTeam: updateTeamReducer
};

export { leagueReducers };