export default (data, searchTerm) => {
  return data.sort((a, b) => {
    if (
      a.name &&
      a.name.toLowerCase().startsWith(searchTerm.toLowerCase().trim())
    )
      return -1;
    if (
      b.name &&
      b.name.toLowerCase().startsWith(searchTerm.toLowerCase().trim())
    )
      return 1;
    return 0;
  });
};
