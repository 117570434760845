import {
  fetchDashboardData,
  fetchAuthors,
  fetchAuthorData,
  fetchAuthorsDataSummary,
  fetchAuthorArticles,
  fetchReport, 
} from './actions/fetchActions';
import {
  dashboardDataReducer,
  authorsReducer,
  authorDataReducer,
  authorsDataSummaryReducer,
  authorArticlesReducer,
  reportDataReducer
} from './reducers/fetchReducers';

const dashReducers = {
  dashboardData: dashboardDataReducer,
  authors: authorsReducer,
  authorData: authorDataReducer,
  authorsDataSummary: authorsDataSummaryReducer,
  authorArticles: authorArticlesReducer,
  reportData: reportDataReducer,
};

export {
  fetchDashboardData,
  fetchAuthors,
  fetchAuthorData,
  dashReducers,
  fetchAuthorsDataSummary,
  fetchAuthorArticles,
  fetchReport
};
