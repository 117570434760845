import {
	articleCategoriesReducer,
	createLiveReducer,
	livePostReducer,
	livePostsReducer,
	updateLiveReducer,
} from "./reducers/reducers";

const liveReducers = {
	createLive: createLiveReducer,
	updateLive: updateLiveReducer,
	livePosts: livePostsReducer,
	articleCategories: articleCategoriesReducer,
	livePost: livePostReducer,
};

export { liveReducers };
