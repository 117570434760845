import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, Icon, ListItemAvatar, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import SideNavLinks from "../components/Sidebar/sideBarRoutes";
import logoImg from "../assets/images/logo.jpg";
import { logout } from "../services/authService";
import identityService from "../services/identityService";
import { setNotifications } from "../redux/notifications/index";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Toast from "../components/MessageToast";
import componentAllowed from "../helpers/authorization/componentAllowed";

const drawerWidth = 260;
const sidebarColor = "#333333";
const sidebarBackground = "#fcfcfc";
const sidebarActiveBackground = "#FF9800";

const styles = makeStyles((theme) => ({
	mainPanel: {
		transitionProperty: "top, bottom, width",
		transitionDuration: ".2s, .2s, .35s",
		transitionTimingFunction: "linear, linear, ease",
		[theme.breakpoints.up("md")]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
		overflow: "auto",
		position: "relative",
		float: "right",
		maxHeight: "100%",
		width: "100%",
		overflowScrolling: "touch",
	},

	scrollBar: {
		"&::-webkit-scrollbar": {
			height: "0.5em",
			width: "0.5em",
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "darkGrey",
			borderRadius: 33,
			outline: "1px solid slategrey",
		},
	},
}));

function ResponsiveDrawer({ children }) {
	const classes = styles();

	const { photo, displayName, email, uid, claims } =
		JSON.parse(localStorage.getItem("au")) || {};
	const [state, setState] = useState({
		mobileOpen: false,
		miniActive: false,
		color: "blue",
		bgColor: "black",
		hasImage: true,
	});
	const [collapsed, setCollapsed] = useState({});
	const dispatch = useDispatch();

	const handleDrawerToggle = () => {
		setState((prev) => ({ ...prev, mobileOpen: !state.mobileOpen }));
	};

	const handleDrawerClose = () => {
		setState((prev) => ({ ...prev, mobileOpen: false }));
	};

	const handleCollapseClick = (item) => {
		setCollapsed((prev) => ({ ...prev, [item]: !collapsed[item] }));
	};

	const sidebarMinimize = () => {
		setState((prev) => ({ ...prev, miniActive: !state.miniActive }));
	};

	const handleLogout = async () => {
		const resp = await identityService({
			path: "/auth/logout",
			method: "GET",
		});
		if (resp.error) {
			Toast({
				message: resp.message,
				type: "error",
			});
		} else {
			const response = await logout();
			if (response.error) {
				Toast({
					message: response.message,
					type: "error",
				});
			} else {
				localStorage.removeItem("au");
				window.location.assign(
					`${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}`
				);
			}
		}
	};

	const notificationState = useSelector((store) => store.notificationsState);

	const onNotificationClick = (notification) => {
		const currentNotifs = notificationState.notifications.filter(
			(notif) => JSON.stringify(notif) !== JSON.stringify(notification)
		);
		// eslint-disable-next-line no-shadow
		const redirect = (dispatch) =>
			new Promise((resolve, reject) => {
				dispatch(setNotifications(currentNotifs));
				resolve();
			});
		dispatch(setNotifications(currentNotifs));
		if (notification.click_action) {
			const url = new URL(notification.click_action);
			if (url.hostname === window.location.hostname) {
				redirect(dispatch).then(() => {
					window.location.assign(url.pathname);
				});
			} else {
				redirect(dispatch).then(() => {
					window.location.assign(notification.click_action);
				});
			}
		}
	};

	const activeRoute = (routeName) => window.location.pathname === routeName;

	const drawer = (
		<Box
			sx={{ overflowY: "auto", overflowX: "hidden", px: 2 }}
			className={classes.scrollBar}
		>
			<List
				sx={{
					background: "#002F65",
					color: "#fff",
					mx: -2,
					position: "sticky",
					top: 0,
					zIndex: 20,
				}}
			>
				<ListItemButton component={NavLink} to="/" onClick={handleDrawerClose}>
					<ListItemAvatar sx={{ minWidth: 0, mr: 2 }}>
						<Avatar
							src={logoImg}
							alt="Logo"
							variant="circular"
							sx={{ width: 30, height: 30 }}
						/>
					</ListItemAvatar>
					<ListItemText primary="InyaRwanda Portal" />
				</ListItemButton>
			</List>
			<Divider />
			<List>
				<ListItemButton onClick={handleDrawerClose}>
					<ListItemAvatar sx={{ minWidth: 0, mr: 2 }}>
						<Avatar
							src={photo}
							alt=""
							variant="circular"
							sx={{ width: 30, height: 30 }}
						/>
					</ListItemAvatar>
					<ListItemText primary={displayName} />
				</ListItemButton>
			</List>
			<Divider />
			<List>
				{SideNavLinks.map((item, index) =>
					item.collapse ? (
						<>
							<ListItemButton onClick={() => handleCollapseClick(item.name)}>
								<ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
									{item.icon ? (
										typeof item.icon === "string" ? (
											<Icon>{item.icon}</Icon>
										) : (
											<item.icon />
										)
									) : (
										<span>{item.mini}</span>
									)}
								</ListItemIcon>
								<ListItemText primary={item.name} />
								{collapsed[item.name] ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={collapsed[item.name]} timeout="auto" unmountOnExit>
								<List component="div">
									{item.views.map((v) => (
										<ListItemButton
											key={v.name}
											disableGutters
											component={NavLink}
											to={v.path}
											sx={
												activeRoute(v.path)
													? {
															background: sidebarActiveBackground,
															":hover": { background: sidebarActiveBackground },

															pl: 4,
													  }
													: { background: sidebarBackground, pl: 4 }
											}
											onClick={handleDrawerClose}
										>
											<ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
												{v.icon ? (
													typeof v.icon === "string" ? (
														<Icon>{v.icon}</Icon>
													) : (
														<v.icon />
													)
												) : (
													<span>{v.mini}</span>
												)}
											</ListItemIcon>
											<ListItemText primary={v.name} />
										</ListItemButton>
									))}
								</List>
							</Collapse>
						</>
					) : (
						componentAllowed(item.roles, item.accessLevel) && (
							<ListItemButton
								key={item.name}
								component={NavLink}
								to={item.path}
								sx={
									activeRoute(item.path)
										? {
												background: sidebarActiveBackground,
												":hover": { background: sidebarActiveBackground },
										  }
										: { background: sidebarBackground }
								}
								onClick={handleDrawerClose}
							>
								<ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
									{item.icon ? (
										typeof item.icon === "string" ? (
											<Icon>{item.icon}</Icon>
										) : (
											<item.icon />
										)
									) : (
										<span>{item.mini}</span>
									)}
								</ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItemButton>
						)
					)
				)}
			</List>
		</Box>
	);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Box
				component="nav"
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					variant="temporary"
					open={state.mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
							background: sidebarBackground,
						},
						background: sidebarBackground,
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
							overflow: "hidden",
							background: sidebarBackground,
							color: sidebarColor,
						},
						overflow: "hidden",
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					p: { xs: 0, sm: 3 },
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					background: "#F8F8F8",
					height: "100vh",
				}}
				className={classes.mainPanel}
			>
				<AdminNavbar
					sidebarMinimize={sidebarMinimize}
					miniActive={state.miniActive}
					handleDrawerToggle={handleDrawerToggle}
					userProfile={{
						image: photo,
						name: displayName,
						email,
						claims: claims || {},
					}}
					logout={handleLogout}
					notifications={notificationState.notifications}
					onNotificationClick={onNotificationClick}
				/>
				<Toolbar />
				<main>{children}</main>
			</Box>
		</Box>
	);
}

export default ResponsiveDrawer;
