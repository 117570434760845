import { auth } from '../config/firebase';

export const loginWithCustomToken = async (customToken) => {
  try {
    const response = await auth.signInWithCustomToken(customToken);
    return response.user;
  } catch (error) {
    return {
      error: true,
      message:
        error.code === 'auth/invalid-custom-token'
          ? 'The token provided is inavlid, please login or contact administrator'
          : error.message.includes('network error')
          ? 'Connection failed, please check your internet'
          : 'Something went wrong, please retry or contact administrator',
    };
  }
};

export const logout = async () => {
  try {
    await auth.signOut();
    return {
      message: 'Signed out successfuly',
    };
  } catch (error) {
    return {
      error: true,
      message: error.message
        ? error.message.includes('network error')
          ? 'Something went wrong, Please check your network or contact your administrator'
          : error.message.replace('signOut', '')
        : "Something went wrong, we couldn't log you out. Please try again.",
    };
  }
};
