import {
	FETCH_GROUPED_AUTHORS,
	FETCH_GROUPED_AUTHOR_DATA,
	FETCH_AUTHOR_DATA,
	FETCH_GROUPED_AUTHORS_SUMMARY,
	FETCH_AUTHOR_ARTICLES,
	FETCH_REPORT,
} from "../types";
import fetchService from "../../../services/fetchService";

const baseUrl = {
	supplyApi: process.env.API_BASE_URL,
};

export const fetchAuthors = () => async (dispatch) =>
	await dispatch({
		type: FETCH_GROUPED_AUTHORS,
		message: `All Authors' data`,
		payload: fetchService(`${baseUrl.supplyApi}/admin/authors`),
	});

export const fetchDashboardData =
	(user, startDate, endDate) => async (dispatch) =>
		await dispatch({
			type: FETCH_GROUPED_AUTHOR_DATA,
			message: `All Authors' grouped data`,
			payload: fetchService(
				`${baseUrl.supplyApi}/admin/summary/articles?startDate=${startDate}&endDate=${endDate}`
			),
		});

export const fetchAuthorData = (uid, startDate, endDate) => async (dispatch) =>
	await dispatch({
		type: FETCH_AUTHOR_DATA,
		message: `All Author data`,
		payload: fetchService(
			`${baseUrl.supplyApi}/admin/summary?uid=${uid}&startDate=${startDate}&endDate=${endDate}`
		),
	});

export const fetchAuthorArticles =
	(uid, startDate, endDate) => async (dispatch) =>
		await dispatch({
			type: FETCH_AUTHOR_ARTICLES,
			message: `All Author data`,
			payload: fetchService(
				`${baseUrl.supplyApi}/admin/summary/articles?uid=${uid}&startDate=${startDate}&endDate=${endDate}`
			),
		});

export const fetchAuthorsDataSummary =
	(startDate, endDate) => async (dispatch) =>
		await dispatch({
			type: FETCH_GROUPED_AUTHORS_SUMMARY,
			message: `summary data`,
			payload: fetchService(
				`${baseUrl.supplyApi}/admin/summary?startDate=${startDate}&endDate=${endDate}`
			),
		});

export const fetchReport = (startDate, endDate) => async (dispatch) =>
	await dispatch({
		type: FETCH_REPORT,
		message: `Report Data`,
		payload: fetchService(
			`${baseUrl.supplyApi}/admin/report?startDate=${startDate}&endDate=${endDate}`
		),
	});
