import {
	CREATE_LIVE_POST,
	UPDATE_LIVE_POST,
	FETCH_ARTICLE_CATEGORIES,
	FETCH_LIVE_POST,
	FETCH_LIVE_POSTS,
} from "../types";

const initialState = {
	loading: false,
	error: null,
	data: {},
};

export const createLiveReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${CREATE_LIVE_POST}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${CREATE_LIVE_POST}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.error ? action.payload.message : null,
			};

		case `${CREATE_LIVE_POST}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const updateLiveReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${UPDATE_LIVE_POST}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${UPDATE_LIVE_POST}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${UPDATE_LIVE_POST}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const livePostsReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_LIVE_POSTS}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${FETCH_LIVE_POSTS}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${FETCH_LIVE_POSTS}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const articleCategoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_ARTICLE_CATEGORIES}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${FETCH_ARTICLE_CATEGORIES}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${FETCH_ARTICLE_CATEGORIES}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};

export const livePostReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${FETCH_LIVE_POST}_PENDING`:
			return {
				...state,
				error: null,
				loading: true,
			};

		case `${FETCH_LIVE_POST}_FULFILLED`:
			return {
				...state,
				loading: false,
				data: action.payload || {},
				error: action.payload.message
					? action.payload.message.includes("Network Error") && "Network Error"
					: null,
			};

		case `${FETCH_LIVE_POST}_REJECTED`:
			return {
				...state,
				loading: false,
				data: null,
				error: `${action.payload}`,
			};
		default:
			return state;
	}
};
