import orgsReducer from "./reducers/orgsReducers";
import appsReducers from "./reducers/appsReducers";

const orgsReducers = {
  orgs: orgsReducer.organizationsReducer,
  org: orgsReducer.organizationReducer,
  appInfo: appsReducers.getAppReducer,
};

export { orgsReducers };
