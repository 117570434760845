import axios from "axios";

const httpInstance = axios.create({
  baseURL: `${process.env.IDENTITY_API}`,
  headers: {
    Accepted: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

httpInstance.interceptors.request.use(
  (config) => {
    config.headers.appId = process.env.APP_ID;
    const user = JSON.parse(localStorage.getItem("au"));
    if (user) {
      config.headers.authorization = user.atkn;
      config.headers.orgId = user.claims.userOrg.orgId
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default httpInstance;
