import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import componentAllowed from "../helpers/authorization/componentAllowed";
import AppLayout from "../layouts/AppLayout";
// import Dashboard from "../views/pages/Dashboard";
// import Authors from "../views/pages/Authors";
// import ArticlePage from "../views/pages/articles/Articles";
// import OrgsPage from "../views/pages/orgs/OrgsPage";
// import PagesPage from "../views/pages/Pages";
// import ReportPage from "../views/pages/Report";
// import CreateArticlePage from "../views/pages/articles/createArticle";
// import Posts from "../views/pages/Posts";
// import PaidAds from "../views/pages/articles/PaidAds";
// import PendingArticles from "../views/pages/articles/PendingArticles";
// import LivePage from "../views/pages/live/LivePosts";
// import CreateLivePage from "../views/pages/live/CreateLivePost";
// import SportsPage from "../views/pages/sports/SportsPage";
// import SeasonPage from "../views/pages/sports/SeasonPage";
// import CreateTeamPage from "../views/pages/sports/CreateTeam";
// import CreateChampiionPage from "../views/pages/sports/CreateChampiion";
// import CreateSeason from "../views/pages/sports/createSeasonPage";
// import CreatematchPage from "../views/pages/sports/CreatematchPage";
// import Banners from "../views/pages/banners/Banners";
// import CreateBanner from "../views/pages/banners/CreateBanner";

const Dashboard = lazy(() => import("../views/pages/Dashboard"));
const Authors = lazy(() => import("../views/pages/Authors"));
const ArticlePage = lazy(() => import("../views/pages/articles/Articles"));
const OrgsPage = lazy(() => import("../views/pages/orgs/OrgsPage"));
const PagesPage = lazy(() => import("../views/pages/Pages"));
const ReportPage = lazy(() => import("../views/pages/Report"));
const CreateArticlePage = lazy(
	() => import("../views/pages/articles/createArticle")
);
const Posts = lazy(() => import("../views/pages/Posts"));
const PaidAds = lazy(() => import("../views/pages/articles/PaidAds"));
const PendingArticles = lazy(
	() => import("../views/pages/articles/PendingArticles")
);
const LivePage = lazy(() => import("../views/pages/live/LivePosts"));
const CreateLivePage = lazy(() => import("../views/pages/live/CreateLivePost"));
const SportsPage = lazy(() => import("../views/pages/sports/SportsPage"));
const SeasonPage = lazy(() => import("../views/pages/sports/SeasonPage"));
const CreateTeamPage = lazy(() => import("../views/pages/sports/CreateTeam"));
const CreateChampiionPage = lazy(
	() => import("../views/pages/sports/CreateChampiion")
);
const CreateSeason = lazy(
	() => import("../views/pages/sports/createSeasonPage")
);
const CreatematchPage = lazy(
	() => import("../views/pages/sports/CreatematchPage")
);
const Banners = lazy(() => import("../views/pages/banners/Banners"));
const CreateBanner = lazy(() => import("../views/pages/banners/CreateBanner"));

const ProtectedRoute = ({
	component: Component,
	roles,
	accessLevel,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			componentAllowed(roles, accessLevel) ? (
				<AppLayout>
					<Suspense fallback={<div></div>}>
						<Component {...props} />
					</Suspense>
				</AppLayout>
			) : (
				<h6>Access denied</h6>
			)
		}
	/>
);

const AppRoute = () => (
	<BrowserRouter>
		<div className="app">
			<Switch>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/"
					component={Dashboard}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/authors"
					component={Authors}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor", "editor"]}
					exact
					path="/authors/:uid"
					component={Authors}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/articles"
					component={ArticlePage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/articles/pending"
					component={PendingArticles}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/articles/new"
					component={CreateArticlePage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/articles/ads/:id"
					component={PaidAds}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/articles/pending/:id"
					component={PendingArticles}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/articles/:id"
					component={ArticlePage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/live"
					component={LivePage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/live/new"
					component={CreateLivePage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/live/:id"
					component={LivePage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/leagues"
					component={SportsPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/leagues/:id"
					component={SportsPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/leagues/:id/seasons/:seasonId"
					component={SeasonPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/teams/new"
					component={CreateTeamPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/champions/new"
					component={CreateChampiionPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/champions/:leagueId/season/new"
					component={CreateSeason}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/leagues/:leagueId/seasons/:seasonId/match/new"
					component={CreatematchPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/sports/leagues/:leagueId/seasons/:seasonId/match/:matchId/edit"
					component={CreatematchPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin"]}
					exact
					path="/orgs"
					component={OrgsPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/orgs/:orgId"
					component={OrgsPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/pages"
					component={PagesPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "editor", "chiefEditor"]}
					exact
					path="/report"
					component={ReportPage}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/posts"
					component={Posts}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/articles/ads"
					component={PaidAds}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/banners"
					component={Banners}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/banners/new"
					component={CreateBanner}
					accessLevel="org"
				/>
				<ProtectedRoute
					roles={["owner", "admin", "chiefEditor"]}
					exact
					path="/banners/:id"
					component={Banners}
					accessLevel="org"
				/>
			</Switch>
		</div>
	</BrowserRouter>
);

export default AppRoute;
