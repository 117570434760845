import {
  createArticleReducer,
  updateArticleReducer,
  articlesReducer,
  articleCategoriesReducer,
  articleReducer,
  articleAdReducer,
  articlesAdsReducer,
} from './reducers/reducers';

const articleReducers = {
  createArticle: createArticleReducer,
  updateArticle: updateArticleReducer,
  articles: articlesReducer,
  articleCategories: articleCategoriesReducer,
  article: articleReducer,
  articlesAds: articlesAdsReducer,
  articleAd: articleAdReducer,
};

export { articleReducers };
