import { UPLOAD_IMAGE_FILE, RESET_IMAGE_STORE } from "../types";
import { uploadToStorageService } from "../../../services/upload/uploadService";

export const uploadImageFile = (data) => async (dispatch) =>
  await dispatch({
    type: UPLOAD_IMAGE_FILE,
    message: "Upload Image file",
    payload: uploadToStorageService("/images/upload", data),
  });

export const resetImageStore = () => (dispatch) =>
  dispatch({
    type: RESET_IMAGE_STORE,
    payload: {},
  });
