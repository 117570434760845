import { FETCH_CLIENTS } from "../types";

const initialState = {
  loading: false,
  data: {},
  error: null,
};

export const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_CLIENTS}_PENDING`:
      return { ...state, error: null, loading: true };

    case `${FETCH_CLIENTS}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error:
          action.payload && action.payload.message
            ? action.payload.message.includes("Network Error") &&
              "Network Error"
            : null,
      };
    case `${FETCH_CLIENTS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };

    default:
      return state;
  }
};
