import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    Accepted: "appication/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("au"));
    if (user) {
      config.headers.authorization = user.atkn;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default async (url, term) => {
  try {
    const response = await axiosInstance.get(url);
    return { ...response, term };
  } catch (error) {
    return error;
  }
};
