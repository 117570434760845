import { 
  FETCH_GROUPED_AUTHORS,
  FETCH_GROUPED_AUTHOR_DATA,
  FETCH_AUTHOR_DATA,
  FETCH_GROUPED_AUTHORS_SUMMARY,
  FETCH_AUTHOR_ARTICLES,
  FETCH_REPORT
} from '../types';

const initialState = {
  loading: false,
  error: null,
  data: [],
};

export const authorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_GROUPED_AUTHORS}_PENDING`:
      return { ...state, error: null, loading: true };

    case `${FETCH_GROUPED_AUTHORS}_FULFILLED`:
    
      const result = action.payload.data && action.payload.data.results ? action.payload.data.results.filter((obj1, index) => {
          const objString = JSON.stringify(obj1.email);
          return index === action.payload.data.results.findIndex(obj2 => JSON.stringify(obj2.email) === objString);
        }): [];
        
      return {
        ...state,
        loading: false,
        data: result.sort((a, b) => b.id - a.id),
        error: action.payload.message
          ? action.payload.message.includes('Network Error') && 'Network Error'
          : null,
      };

    case `${FETCH_GROUPED_AUTHORS}_REJECTED`:
      return {
        ...state,
        loading: false,
        data: null,
        error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const authorArticlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_AUTHOR_ARTICLES}_PENDING`:
      return {
        ...state, error: null, loading: true
      };

    case `${FETCH_AUTHOR_ARTICLES}_FULFILLED`:
      return {
        ...state,
        loading: false,
          data: action.payload.data ? action.payload.data.results : [],
          error: action.payload.message ?
          action.payload.message.includes('Network Error') && 'Network Error' :
          null,
      };

    case `${FETCH_AUTHOR_ARTICLES}_REJECTED`:
      return {
        ...state,
        loading: false,
          data: null,
          error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const dashboardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_GROUPED_AUTHOR_DATA}_PENDING`:
      return {
        ...state, error: null, loading: true
      };

    case `${FETCH_GROUPED_AUTHOR_DATA}_FULFILLED`:
      return {
        ...state,
        loading: false,
          data: action.payload.data ? action.payload.data.results : [],
          error: action.payload.message ?
          action.payload.message.includes('Network Error') && 'Network Error' :
          null,
      };

    case `${FETCH_GROUPED_AUTHOR_DATA}_REJECTED`:
      return {
        ...state,
        loading: false,
          data: null,
          error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const authorDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_AUTHOR_DATA}_PENDING`:
      return {
        ...state, error: null, loading: true
      };

    case `${FETCH_AUTHOR_DATA}_FULFILLED`:
      return {
          ...state,
          loading: false,
          data: action.payload.data ? action.payload.data : {},
          error: action.payload.message ?
            action.payload.message.includes('Network Error') && 'Network Error' :
            null,
      };

    case `${FETCH_AUTHOR_DATA}_REJECTED`:
      return {
        ...state,
        loading: false,
          data: null,
          error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const authorsDataSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_GROUPED_AUTHORS_SUMMARY}_PENDING`:
      return {
        ...state, error: null, loading: true
      };

    case `${FETCH_GROUPED_AUTHORS_SUMMARY}_FULFILLED`:
      return {
        ...state,
        loading: false,
          data: action.payload.data ? action.payload.data.results : [],
          error: action.payload.message ?
          action.payload.message.includes('Network Error') && 'Network Error' :
          null,
      };

    case `${FETCH_GROUPED_AUTHORS_SUMMARY}_REJECTED`:
      return {
        ...state,
        loading: false,
          data: null,
          error: `${action.payload}`,
      };
    default:
      return state;
  }
};

export const reportDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_REPORT}_PENDING`:
      return {
        ...state, error: null, loading: true
      };

    case `${FETCH_REPORT}_FULFILLED`:
      return {
        ...state,
        loading: false,
          data: action.payload.data ? action.payload.data.results : [],
          error: action.payload.message ?
          action.payload.message.includes('Network Error') && 'Network Error' :
          null,
      };

    case `${FETCH_REPORT}_REJECTED`:
      return {
        ...state,
        loading: false,
          data: null,
          error: `${action.payload}`,
      };
    default:
      return state;
  }
};
