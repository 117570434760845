import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
// import reducers
import { dashReducers } from "./dashboard/index";
import { orgsReducers } from "./orgs/index";
import { notificationsReducers } from "./notifications";
import { uploadReducers } from "./upload";
import { articleReducers } from "./articles";
import { searchReducers } from "./search";
import { clientsReducers } from "./clients";
import { postsReducers } from "./posts";
import { liveReducers } from "./live";
import { leagueReducers } from "./sports";
import { bannersReducers } from "./banners";

const reducers = combineReducers({
	...dashReducers,
	...orgsReducers,
	...notificationsReducers,
	...uploadReducers,
	...articleReducers,
	...clientsReducers,
	...searchReducers,
	...postsReducers,
	...liveReducers,
	...leagueReducers,
	...bannersReducers,
});

export default () => {
	const store = createStore(
		reducers,
		{},
		composeWithDevTools(applyMiddleware(thunk, promise))
	);

	return store;
};
