import { UPLOAD_IMAGE_FILE, RESET_IMAGE_STORE } from "../types";

const initialState = {
  loading: false,
  data: {},
  error: {},
};

export const uploadImageFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_IMAGE_STORE:
      return initialState;

    case `${UPLOAD_IMAGE_FILE}_PENDING`:
      return { ...state, data: {}, error: {}, loading: true };

    case `${UPLOAD_IMAGE_FILE}_FULFILLED`:
      return {
        ...state,
        loading: false,
        data: action.payload.response ? action.payload.response : {},
        error: action.payload.error ? action.payload.errorMessage : {},
      };

    case `${UPLOAD_IMAGE_FILE}_REJECTED`:
      return { ...state, loading: false, data: {}, error: `${action.payload}` };
    default:
      return state;
  }
};
